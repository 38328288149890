<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Payouts</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <form @submit="update_payout" class="form" method="post">
            <div class="form-wrap">
              <label for="user">Nutzer</label>
              <input v-model="payout.user.email" class="form-input" type="text" name="user" id="user" />
            </div>

            <div class="form-wrap">
              <label for="amount">Betrag</label>
              <input v-model="payout.amount_formatted" class="form-input" type="text" name="amount" id="amount" />
            </div>

            <div class="form-wrap">
              <label for="date">Datum</label>
              <input v-model="payout.created_at_formatted" class="form-input" type="text" name="date" id="date" />
            </div>

            <div class="form-wrap select-wrap">
              <label for="status">Status</label>
              <select v-model="payout.status" class="select-wrap">
                <option value="pending">Angefragt</option>
                <option value="completed">Ausgezahlt</option>
              </select>
            </div>

            <p style="margin-bottom: 0; text-align: right">
              <input type="submit" :value="$t('global.saveNow')" class="button button-ci button-round button-100" />
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "dashboard",
  components: {
    AdminNav,
  },
  data() {
    return {
      errors: [],
      payout: {
        user: [],
      },
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/payouts/" + this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.payout = response.data.payout;
        });
    },
    update_payout: function (e) {
      e.preventDefault();

      axios
        .patch(process.env.VUE_APP_BASE_API + "/admin/payouts/" + this.$route.params.uuid, this.payout, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: "Erfolgreich gespeichert.",
          });
          this.get_data();
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
